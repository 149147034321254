.tabSystem {
  display: flex;
  border-bottom: var(--sbt-border-default);
  margin-top: var(--sbt-spacing-sm);
}

.tab {
  padding: var(--sbt-spacing-md) var(--sbt-spacing-xs);
  color: var(--sbt-placeholder-text-color);
  flex: 1;
  text-align: center;
  position: relative;

  &.active {
    color: var(--sbt-primary-color);
  }

  &.active::after {
    position: absolute;
    content: '';
    color: var(--sbt-primary-color);
    border-bottom: 4px solid var(--sbt-primary-color);
    border-radius: var(--sbt-corner-sm) var(--sbt-corner-sm)
      var(--sbt-corner-null) var(--sbt-corner-null);
    width: 70%;
    transform: translateX(-50%);
    bottom: 0;
    left: 50%;
  }
}

.page {
  padding: var(--sbt-spacing-lg) var(--sbt-spacing-xl);
  height: 100%;
}

.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabContent {
  height: 100%;
}
